(function () {
  'use strict';

  angular
    .module('wbAuth')
    .config(config);

  function config($stateProvider, notForAuthenticated, authenticatedOnly) {
    $stateProvider
      .state('login', {
        url: '/login',
        templateUrl: 'wb-auth/views/login.tpl.html',
        controller: 'LoginCtrl',
        controllerAs: 'Login',
        data: {
          skipNavigation: true
        },
        restrict: notForAuthenticated
      })
      .state('adminLogin', {
        url: '/admin-login',
        templateUrl: 'wb-auth/views/admin-login.tpl.html',
        controller: 'AdminLoginCtrl',
        controllerAs: 'AdminLogin',
        data: {
          skipNavigation: true
        },
        restrict: notForAuthenticated
      })
      .state('passwordReset', {
        url: '/password-reset',
        templateUrl: 'wb-auth/views/password-reset.tpl.html',
        controller: 'PasswordResetCtrl',
        controllerAs: 'PasswordReset',
        data: {
          skipNavigation: true
        },
        restrict: notForAuthenticated
      })
      .state('passwordChange', {
        url: '/change-password',
        templateUrl: 'wb-auth/views/password-change.tpl.html',
        controller: 'PasswordChangeCtrl',
        controllerAs: 'PasswordChange',
        resolve: {
          user: function (LoggedUser) {
            return LoggedUser.getUser();
          }
        },
        restrict: authenticatedOnly
      })
      .state('logout', {
        url: '/logout',
        controller: 'LogoutCtrl',
        controllerAs: 'logout',
        restrict: authenticatedOnly
      })
      .state('passwordResetConfirm', {
        url: '/password-reset/:confirmationCode',
        templateUrl: 'wb-auth/views/password-reset-confirm.tpl.html',
        controller: 'PasswordResetConfirmCtrl',
        controllerAs: 'passwordResetConfirm',
        resolve: {
          confirmationCode: function ($stateParams) {
            return $stateParams.confirmationCode;
          }
        },
        data: {
          skipNavigation: true
        },
        restrict: notForAuthenticated
      });
  }
}());
